import api from "@/services/api";

export default {
	/* CREATE */

	async create(data) {
		try {
			return api
				.post(`v1/profiles/me`, data)
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					return err.response.data;
				});
		} catch (error) {
			// Handle the error as needed, e.g., log it or rethrow it
			return false; // or null, depending on your use case
		}
	},

	async switch(id) {
		try {
			return api
				.post(`v1/profiles/${id}/switch`, {})
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					return err.response.data;
				});
		} catch (error) {
			// Handle the error as needed, e.g., log it or rethrow it
			return false; // or null, depending on your use case
		}
	},

	/* READ */

	async get(id) {
		try {
			return api
				.get(`v1/profiles/${id}`)
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					return err.response.data;
				});
		} catch (error) {
			// Handle the error as needed, e.g., log it or rethrow it
			return false; // or null, depending on your use case
		}
	},

	async getAll(role = null) {
		try {
			return api
				.get(`v1/profiles/me${role !== null ? `?role=${role}` : ""}`)
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					return err.response.data;
				});
		} catch (error) {
			// Handle the error as needed, e.g., log it or rethrow it
			return false; // or null, depending on your use case
		}
	},

	
	/* UPDATE */

	async update(id, data) {
		try {
			return api
				.patch(`v1/profiles/${id}`, data)
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					return err.response.data;
				});
		} catch (error) {
			// Handle the error as needed, e.g., log it or rethrow it
			return false; // or null, depending on your use case
		}
	},

	/* DELETE */

	async delete(id) {
		try {
			return api
				.delete(`v1/profiles/${id}`)
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					return err.response.data;
				});
		} catch (error) {
			// Handle the error as needed, e.g., log it or rethrow it
			return false; // or null, depending on your use case
		}
	},
};
