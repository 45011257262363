import { createApp } from "vue";
import setupInterceptors from "./services/setupInterceptors";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import { useMainStore } from "./stores/store";
import vuetify from "@/plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";

import "./helpers.js";

/************************************************
 * Toast
 ************************************************/
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import VueApexCharts from "vue3-apexcharts";
// Vue.config.productionTip = false
import VueChartkick from "vue-chartkick";
import OneSignalVuePlugin from "@onesignal/onesignal-vue3";
import "chartkick/chart.js";

const pinia = createPinia();

setupInterceptors();
loadFonts();

const app = createApp(App)
	.use(router)
	.use(pinia)
	.use(useMainStore())
	.use(vuetify)
	.use(VueDatePicker)
	.use(VueApexCharts)
	.use(VueChartkick)
	.use(OneSignalVuePlugin, {
		appId: "670af6ef-80bd-4c4b-a710-b3108c3c5040",
		safari_web_id: "web.onesignal.auto.49809676-9f7f-4916-aef3-fd94958742a1",
		notifyButton: {
			enable: false,
		},
		allowLocalhostAsSecureOrigin: true,
	});

app.config.globalProperties.$toast = toast;

// Import custom styles after initializing Vuetify
import "./assets/scss/styles.scss";

app.mount("#app");
